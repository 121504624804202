import { type FC, type LazyExoticComponent, lazy } from "react"

import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player/vimeo"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { type TRichTextRendererProps } from "@/shared/rich-text-renderer"

import { ChapterComponent, IMediaVideoVimeo } from "Manager/chapter/models/types"

import NextButton from "./NextButton"

const RichTextRenderer: LazyExoticComponent<FC<TRichTextRendererProps>> = lazy(
    (): Promise<{ default: FC<TRichTextRendererProps> }> => import("@/shared/rich-text-renderer")
)

interface IProps {
    component: IMediaVideoVimeo
    onNext: (component: ChapterComponent) => void
    isLast: boolean
    disabledBtn: boolean
}

const MediaVideo: FC<IProps> = ({ component, onNext, isLast = false, disabledBtn }) => {
    const { t } = useTranslation()

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const onClick = () => {
        onNext(component)
    }

    return (
        <div className="container--phase container--phase-lg video-holder">
            <div className="px-0 video-block py-3">
                <ReactPlayer
                    className="react-player"
                    url={component?.video_id as string}
                    controls
                    playing={false}
                    onError={e => {
                        console.log(e)
                    }}
                    width="100%"
                    height="100%"
                    playsinline
                />
            </div>
            <div className="mt-3 mt-lg-0 ml-lg-5 px-3 px-lg-0">
                <h2 className="h5 font-extrabold text-uppercase color-brand text-center text-lg-left">{t("LEARN")}</h2>
                <h3 className="h2 font-extrabold font-italic text-center text-lg-left">
                    {getValueFromTranslatedObject(component?.title)}
                </h3>

                <div className="my-1">
                    <RichTextRenderer content={component?.content} />
                </div>

                <div className="text-center my-4.5">
                    <NextButton isLast={isLast} onClick={onClick} disabled={disabledBtn} />
                </div>
            </div>
        </div>
    )
}

export default MediaVideo
