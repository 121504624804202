import { type FC, type LazyExoticComponent, type ReactElement, lazy, useMemo } from "react"

import { type SetURLSearchParams, useSearchParams } from "react-router-dom"

import type { TEmailBannerConfig } from "./types"
import { type TCoachWelcomePageUtils as TUtils, coachWelcomePageUtils as utils } from "./utils"

import { CoachWelcomeEmailBanner as EmailBanner, CoachWelcomeHeading as Heading } from "."

const { getEmailBannerConfigFromUri }: TUtils = utils

const UserList: LazyExoticComponent<FC> = lazy((): Promise<{ default: FC }> => import("./CoachWelcomeUserList"))

const Container: FC = (): ReactElement => {
    const [searchParams]: [URLSearchParams, SetURLSearchParams] = useSearchParams()

    const { isVisible: isEmailBannerVisible, productType: emailBannerProductType }: TEmailBannerConfig = useMemo(
        (): TEmailBannerConfig => getEmailBannerConfigFromUri(searchParams),
        [searchParams]
    )

    return (
        <div className="coach-welcome__container">
            <Heading />
            <EmailBanner isVisible={isEmailBannerVisible} productType={emailBannerProductType} />
            <UserList />
        </div>
    )
}

export { Container as CoachWelcomeContainer }
