import { type FC, type LazyExoticComponent, lazy, useEffect, useMemo, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useMediaQuery from "$/hooks/use-media-query"

import { FREE_RESPONSE_TEXT_LENGTH, MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { Summary } from "@/models/summary"
import { type TRichTextRendererProps } from "@/shared/rich-text-renderer"
import { sanitizeContent } from "@/utils/common"

import {
    ChapterComponent,
    IQuestionLikerGroup,
    IResponseOption,
    ISingleQuestionLikerGroup
} from "Manager/chapter/models/types"

import { WarningMessage } from "../onboarding/components/WarningMessage"

import NextButton from "./NextButton"
import ResultsLikertGroup from "./ResultsLikertGroup"

import "./styles.scss"

const RichTextRenderer: LazyExoticComponent<FC<TRichTextRendererProps>> = lazy(
    (): Promise<{ default: FC<TRichTextRendererProps> }> => import("@/shared/rich-text-renderer")
)

interface IProps {
    component: IQuestionLikerGroup
    onNext: (component: ChapterComponent, answer?: any, isLastQuestion?: boolean) => void
    onNextNavigation: () => void
    setQuestionsProgressBar: (progress: number) => void
    isLast: boolean
    likertQuestionsWithSummary: string[]
    chapterSummary: Summary
    isEditable: boolean
    disabledBtn
}

const QuestionLikertGroup: FC<IProps> = ({
    component,
    onNext,
    isLast,
    onNextNavigation,
    likertQuestionsWithSummary,
    chapterSummary,
    setQuestionsProgressBar,
    isEditable,
    disabledBtn
}) => {
    const { t } = useTranslation()
    const [currentQuestion, setCurrentQuestion] = useState<ISingleQuestionLikerGroup>(null)
    const [toggleSummary, setToggleSummary] = useState(false)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [answeredOptions, setAnsweredOptions] = useState([])
    const [currentAnsweredOption, setCurrentAnsweredOption] = useState(null)
    const [freeResponse, setFreeResponse] = useState(currentAnsweredOption?.free_response ?? "")
    const [notApplicable, setNotApplicable] = useState(currentAnsweredOption?.not_applicable_input ?? "")
    const [toggleApplicable, setToggleApplicable] = useState(currentAnsweredOption?.not_applicable_input ?? false)
    const [hasError, setError] = useState(null)
    const isMobileView = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const hasAnsweredAllQuestions = component?.answer_data?.answer_list?.length === component?.question_list?.length

    useEffect(() => {
        setCurrentQuestion(component?.question_list?.find(question => question?.uuid))
        if (component && "answer_data" in component) {
            setAnsweredOptions(component?.answer_data?.answer_list)

            if (component.show_summary_after_finish && !isEditable && hasAnsweredAllQuestions) {
                setToggleSummary(true)
            }
        }

        return () => resetStates()
    }, [component])

    useEffect(() => {
        if (answeredOptions.length > 0) {
            const answer = getCurrentAnsweredOption()
            setCurrentAnsweredOption(answer ?? null)
            setFreeResponse(answer?.free_response ?? "")
            setNotApplicable(answer?.not_applicable_input ?? "")
        }
    }, [answeredOptions, currentQuestion])

    useEffect(() => {
        computeQuestionsProgress()
    }, [currentQuestionIndex])

    const resetStates = () => {
        setToggleSummary(false)
        setCurrentQuestionIndex(0)
        setAnsweredOptions([])
        setCurrentQuestion(null)
        setFreeResponse("")
        setNotApplicable("")
        setToggleApplicable(false)
        setError(null)
    }

    const lastQuestionIndex = component?.question_list?.length - 1

    const onOptionSelect = (option: IResponseOption) => {
        if (isNotEditableAndHasAnswer()) {
            return
        }

        const answered = getCurrentAnsweredOption()

        const answer = {
            ...option,
            option_uuid: option.uuid,
            options: currentQuestion?.options,
            free_response: "",
            question_uuid: currentQuestion.uuid,
            question: currentQuestion?.question?.en,
            not_applicable: false,
            not_applicable_input: answered?.not_applicable_input
        }

        setToggleApplicable(false)
        setNotApplicable("")

        if (answered) {
            setAnsweredOptions(prev => prev.map(o => (o.question_uuid === currentQuestion.uuid ? answer : o)))
        } else {
            setAnsweredOptions(prev => [...prev, answer])
        }
        setError(null)
    }

    const computeQuestionsProgress = () => {
        const totalQuestions = component?.question_list?.length
        const questionProgress = (currentQuestionIndex / totalQuestions) * 100
        setQuestionsProgressBar(Math.round(questionProgress))
    }

    const onNextQuestion = (e?) => {
        e?.preventDefault()

        const answer = getCurrentAnsweredOption()

        if (component?.required && ((!answer?.option_uuid && !answer?.not_applicable) || !answer)) {
            setError(t("Choose your answer"))
            return
        }

        if (hasError) {
            return
        }

        if (currentQuestionIndex < lastQuestionIndex) {
            setCurrentQuestion(component?.question_list[currentQuestionIndex + 1])
            setToggleApplicable(false)
            setNotApplicable("")
            setCurrentQuestionIndex(questionIndex => questionIndex + 1)
            setError(null)
        }

        if (currentQuestionIndex <= lastQuestionIndex) {
            submitQuestion()
        }
    }

    const onPrevQuestion = e => {
        e.preventDefault()

        if (currentQuestionIndex > 0) {
            setCurrentQuestion(component?.question_list[currentQuestionIndex - 1])
            setToggleApplicable(false)
            setNotApplicable("")
            setCurrentQuestionIndex(currentQuestionIndex - 1)
            setError(null)
        }
    }

    const onChangeFreeResponse = (value: string) => {
        if (isNotEditableAndHasAnswer()) {
            return
        }

        setFreeResponse(value)

        if (isInvalidFieldLength(value)) {
            return
        }

        setAnsweredOptions(prev =>
            prev.map(o => (o.question_uuid === currentQuestion.uuid ? { ...o, free_response: value } : o))
        )
    }

    const onChangeNotApplicable = (value: string) => {
        if (isNotEditableAndHasAnswer()) {
            return
        }

        setNotApplicable(value)

        if (isInvalidFieldLength(value)) {
            return
        }
        setAnsweredOptions(prev =>
            prev.map(o => (o.question_uuid === currentQuestion.uuid ? { ...o, not_applicable_input: value } : o))
        )
    }

    const isInvalidFieldLength = (value: string) => {
        if (value.length <= FREE_RESPONSE_TEXT_LENGTH && hasError) {
            setError(null)
        }

        if (value.length > FREE_RESPONSE_TEXT_LENGTH) {
            setError(
                t("Ensure this field has no more than {{FREE_RESPONSE_TEXT_LENGTH}} characters.", {
                    FREE_RESPONSE_TEXT_LENGTH
                })
            )
            return true
        }

        return false
    }

    const onToggleNotApplicable = e => {
        if (isNotEditableAndHasAnswer()) {
            return
        }

        const { checked } = e.target

        setToggleApplicable(checked)

        if (!checked) {
            const answer = {
                question_uuid: currentQuestion.uuid,
                question: currentQuestion?.question?.en,
                not_applicable: false,
                not_applicable_input: currentAnsweredOption?.not_applicable_input,
                option_uuid: ""
            }
            setAnsweredOptions(prev => prev.map(o => (o.question_uuid === currentQuestion.uuid ? answer : o)))
            return
        }

        const answered = getCurrentAnsweredOption()
        const answer = {
            question_uuid: currentQuestion.uuid,
            not_applicable_input: answered?.not_applicable_input,
            not_applicable: true,
            question: currentQuestion?.question?.en
        }

        if (answered) {
            setAnsweredOptions(prev => prev.map(o => (o.question_uuid === currentQuestion.uuid ? answer : o)))
        } else {
            setAnsweredOptions(prev => [...prev, answer])
        }
        setError(null)
    }

    const submitQuestion = () => {
        const answer = getCurrentAnsweredOption()
        if ((component?.required && !answer) || (!answer.not_applicable && !answer.option_uuid)) {
            setError(t("Choose your answer"))
            return
        }

        const answerList = answeredOptions
            .map(o => ({
                option_uuid: o.option_uuid || "",
                free_response: o.free_response || "",
                question_uuid: o.question_uuid,
                not_applicable_input: o.option_uuid ? "" : o.not_applicable_input || "",
                not_applicable: o.not_applicable
            }))
            .filter(o => o.option_uuid || o.not_applicable)

        const isLastQuestion = currentQuestionIndex === lastQuestionIndex
        const isLastQuestionLikertGroup =
            likertQuestionsWithSummary[likertQuestionsWithSummary.length - 1] === component.uuid
        const showSummary = component?.show_summary_after_finish

        let skipNavigation = false

        if (isLastQuestionLikertGroup && isLastQuestion && showSummary) {
            setToggleSummary(true)
            skipNavigation = true
        }

        if (isNotEditableAndHasAnswer()) {
            if (isLastQuestion) {
                onNext(component)
            }
            return
        }

        onNext(component, answerList, skipNavigation || !(isLastQuestion && !skipNavigation))
    }

    const getCheckedAnswer = (option: IResponseOption) => {
        const answer = answeredOptions.find(answer => answer?.option_uuid === option.uuid)
        return !!answer
    }

    const getCurrentAnsweredOption = () => {
        const answer = answeredOptions.find(o => o.question_uuid === currentQuestion.uuid)
        return answer
    }

    const getFreeResponsePlaceHolder = () => {
        return getValueFromTranslatedObject(currentAnsweredOption?.free_response_prompt)
            ?.replace(/<[^>]*>/g, "")
            ?.replace(/([^\w\s])\1+/g, "")
            ?.trim()
    }

    const getFreeResponseValue = () => {
        if (isNotEditableAndHasAnswer()) {
            return currentAnsweredOption?.free_response
        }

        return freeResponse
    }

    const getApplicableCheckedStatus = () => {
        if ("answer_data" in component && currentAnsweredOption) {
            return (
                currentAnsweredOption?.not_applicable ??
                ("not_applicable_input" in currentAnsweredOption && !currentAnsweredOption.option_uuid)
            )
        }
        const answer = getCurrentAnsweredOption()
        if (answer) {
            return answer?.not_applicable
        }
        return toggleApplicable
    }

    const getApplicableValue = () => {
        if (isNotEditableAndHasAnswer()) {
            return currentAnsweredOption?.not_applicable_input
        }

        return notApplicable
    }

    const hasFreeResponse = useMemo(() => {
        if (currentQuestion && currentAnsweredOption) {
            return currentQuestion.options.find(o => o.uuid === currentAnsweredOption.option_uuid)?.allow_free_response
        }
    }, [currentQuestion, currentAnsweredOption])

    const isNotEditableAndHasAnswer = () => "answer_data" in component && !isEditable && hasAnsweredAllQuestions

    const getPlaceHolderValueNotApplicable = () => {
        return getValueFromTranslatedObject(currentQuestion?.not_applicable_prompt)
            ? getValueFromTranslatedObject(currentQuestion?.not_applicable_prompt)
                  ?.replace(/<[^>]*>/g, "")
                  ?.replace(/([^\w\s])\1+/g, "")
                  ?.trim()
            : t("Start typing...")
    }

    const showLabel = (index: number, optionUuid: string, options: any[]) => {
        const answeredOption = getCurrentAnsweredOption()
        const isFirstOrLastOption = (index === 0 || index === options.length - 1) && !answeredOption
        const isAnswered = answeredOption?.option_uuid === optionUuid

        return !isMobileView || isFirstOrLastOption || isAnswered
    }

    const isLastQuestionAndLastComponent =
        isLast && currentQuestionIndex === lastQuestionIndex && !component.show_summary_after_finish

    return (
        <>
            {toggleSummary ? (
                <ResultsLikertGroup
                    disabledBtn={disabledBtn}
                    likertQuestionsWithSummary={likertQuestionsWithSummary}
                    onNextNavigation={onNextNavigation}
                    onNextComponent={onNext}
                    component={component}
                    isLast={isLast}
                    chapterSummary={chapterSummary}
                />
            ) : (
                <div className="container--phase pt-5 text-center">
                    <h1 className="h2 font-extrabold font-italic">{getValueFromTranslatedObject(component?.title)}</h1>
                    <div className="pager-phase">
                        <a
                            href="#"
                            className={classNames("pager-phase-link", { inactive: currentQuestionIndex === 0 })}
                            onClick={onPrevQuestion}
                        >
                            <svg viewBox="0 0 9 14">
                                <path d="M7.5 12L2.5 7L7.5 2" fill="none" stroke="currentColor" strokeWidth="3" />
                            </svg>
                        </a>
                        <span className="pager-phase-title h5 color-brand text-uppercase font-extrabold mb-0">
                            {t("QUESTION {{questionIndex}} of {{questionsLength}}", {
                                questionIndex: currentQuestionIndex + 1,
                                questionsLength: component?.question_list?.length
                            })}
                        </span>
                        <a
                            href="#"
                            className={classNames("pager-phase-link", {
                                inactive: currentQuestionIndex === lastQuestionIndex
                            })}
                            onClick={onNextQuestion}
                        >
                            <svg viewBox="0 0 9 14">
                                <path d="M1.5 2L6.5 7L1.5 12" fill="none" stroke="currentColor" strokeWidth="3" />
                            </svg>
                        </a>
                    </div>

                    <div className="my-2">
                        <RichTextRenderer content={currentQuestion?.question} />
                    </div>

                    <WarningMessage message={hasError} className="question-likert-error-msg" />
                    <div className="level-choice level-choice--brand d-flex justify-content-center">
                        {currentQuestion?.options?.length > 0 &&
                            currentQuestion?.options?.map((option, index, options) => (
                                <div className="level-choice-radio" key={option?.uuid}>
                                    <input
                                        type="radio"
                                        name="phase-level"
                                        id={`${index}-${getValueFromTranslatedObject(option.response)}`}
                                        checked={getCheckedAnswer(option)}
                                        onChange={() => onOptionSelect(option)}
                                    />
                                    <label htmlFor={`${index}-${getValueFromTranslatedObject(option.response)}`}>
                                        {showLabel(index, option.uuid, options) ? (
                                            <span>
                                                {sanitizeContent(getValueFromTranslatedObject(option.response), {
                                                    allowedTags: []
                                                })}
                                            </span>
                                        ) : null}
                                    </label>
                                </div>
                            ))}
                    </div>
                    {currentQuestion?.not_applicable && (
                        <label
                            className={classNames("not-applicable-checkbox color-gray my-4", {
                                underline: getApplicableCheckedStatus()
                            })}
                        >
                            {getValueFromTranslatedObject(currentQuestion?.not_applicable_label) ||
                                t("This doesn't apply to me")}
                            <input
                                type="checkbox"
                                checked={getApplicableCheckedStatus()}
                                onChange={onToggleNotApplicable}
                            />
                            <span className="not-applicable-checkbox-checkmark" />
                        </label>
                    )}
                    {(currentAnsweredOption?.allow_free_response || hasFreeResponse) && (
                        <textarea
                            className="participant-textarea mb-6"
                            placeholder={getFreeResponsePlaceHolder()}
                            value={getFreeResponseValue()}
                            disabled={isNotEditableAndHasAnswer()}
                            onChange={e => onChangeFreeResponse(e.target.value)}
                        />
                    )}
                    {currentQuestion?.not_applicable && getApplicableCheckedStatus() && (
                        <textarea
                            className="participant-textarea mb-6"
                            placeholder={getPlaceHolderValueNotApplicable()}
                            value={getApplicableValue()}
                            disabled={isNotEditableAndHasAnswer()}
                            onChange={e => onChangeNotApplicable(e.target.value)}
                        />
                    )}
                    <div id="blockHidden">
                        <NextButton
                            className="mt-4 mb-6 "
                            onClick={onNextQuestion}
                            isLast={isLastQuestionAndLastComponent}
                            disabled={disabledBtn}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
export default QuestionLikertGroup
