import { useCallback } from "react"

import type { TranslationObj as ITranslationModel } from "$/utils/lang"

import { type IAuthContext, useAuthContext } from "@/context"

type TGetValueFromTranslatedObjectFunction = <T extends ITranslationModel>(obj: T) => string

/**
 * @return Returns a function for taking translation from Translation object
 */
function useGetValueFromTranslatedObject(): TGetValueFromTranslatedObjectFunction {
    const { user }: IAuthContext = useAuthContext()

    return useCallback(
        <T extends ITranslationModel>(obj: T): string => obj?.[user?.lang] ?? obj?.en ?? String(),
        [user?.lang]
    )
}

export { useGetValueFromTranslatedObject, type TGetValueFromTranslatedObjectFunction }
