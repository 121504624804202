import { useRef, useState } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Link, matchPath, useLocation } from "react-router-dom"

import { useOnClickOutside } from "$/hooks/use-outside-click"

import type { IParticipantModel } from "@/adapters"
import { StepUrls as OnboardingUrls, onboardingProfileSetupURL } from "@/components/onboarding/constants"
import { ParticipantUrls } from "@/constants"
import User from "@/models/user"
import Avatar from "@/shared/avatar/Avatar"
import Button from "@/shared/button/Button"
import { HeaderDropdownProfileList } from "@/shared/header-dropdown-profile-list"
import { UserDefaultAvatar } from "@/svgs"
import useRoles from "@/utils/hooks/use-roles"

import "./styles.scss"

type Props = {
    handleLogout: (e) => void
    showOnMobile?: boolean
    user: User
    className?: string
}

const UserHeaderDropdown = ({ user, showOnMobile = false, className = "", handleLogout }: Props) => {
    const { t } = useTranslation()
    const location = useLocation()
    const { isCoaches } = useRoles()
    const [isActiveMenu, setIsActiveMenu] = useState(false)
    const outsideRefClick = useRef<HTMLDivElement>()

    const toggleActiveMenu = () => setIsActiveMenu(prev => !prev)

    const showProfile =
        !Object.values({
            ...OnboardingUrls,
            onboardingProfileSetupURL,
            profile: ParticipantUrls.PROFILE,
            assignModules: ParticipantUrls.ASSIGN_MODULE
        }).some(path => matchPath(path, location.pathname)) && !isCoaches

    useOnClickOutside(outsideRefClick, (): void => setIsActiveMenu(false))

    return (
        <div
            className={classNames(
                "d-md-block user-header-dropdown",
                {
                    "d-none": !showOnMobile,
                    "fake-mode": (user as IParticipantModel)?.isFakeParticipant
                },
                className
            )}
            ref={outsideRefClick}
        >
            <div className="position-relative">
                <div className="dropdown header-nav-dropdown">
                    <Button
                        className="p-0 user-header-dropdown-button"
                        type="button"
                        variant="default"
                        id="userDropdownButton"
                        data-testid="userDropdownButton"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        onClick={toggleActiveMenu}
                    >
                        <span className="image--53 image--rounded">
                            {user?.photo ? (
                                <div className="user-profile-wrapper">
                                    <div className={classNames("user-profile-picture", { show: isActiveMenu })}>
                                        <Avatar width={37} height={37} url={user?.photo} />
                                    </div>
                                </div>
                            ) : (
                                <div className={classNames("user-profile-picture", { show: isActiveMenu })}>
                                    <UserDefaultAvatar isActive={isActiveMenu} />
                                </div>
                            )}
                        </span>
                    </Button>
                </div>
                <div className={classNames("dropdown-header-menu", { show: isActiveMenu })}>
                    {showProfile && (
                        <Link className="dropdown-header-item" to={ParticipantUrls.PROFILE}>
                            {t("My Profile")}
                        </Link>
                    )}
                    <Link className="dropdown-header-item" to="#" onClick={handleLogout}>
                        {t("Sign Out")}
                    </Link>
                    {(user as IParticipantModel)?.isFakeParticipant && <HeaderDropdownProfileList />}
                </div>
            </div>
        </div>
    )
}

export default UserHeaderDropdown
