import { type NamedExoticComponent, type ReactElement, Suspense, memo, useMemo } from "react"

import { CKEditor } from "@ckeditor/ckeditor5-react"
// eslint-disable-next-line
import Editor from "ckeditor5-custom-build/ckeditor"

import { isObject, isString } from "$/utils/gates"
import { type TranslationObj } from "$/utils/lang"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { Spinner } from "@/shared/spinner"
import { emptyCallback } from "@/shared/types/functions"
import { sanitizeContent } from "@/utils/common"

import "./RichTextRenderer.styles.scss"

type TProps = { content?: TranslationObj | string }

const domParser: DOMParser = new DOMParser()

function propsChecker({ content: prevContent }: TProps, { content: nextContent }: TProps): boolean {
    if (!prevContent) return false

    return isObject(nextContent)
        ? (prevContent as TranslationObj)?.en?.length === (nextContent as TranslationObj)?.en?.length
        : (prevContent as string)?.length === nextContent?.length
}

function isStringValidHTML(content: string): boolean {
    try {
        const parsedDocument: Document = domParser.parseFromString(content, "text/html")

        return parsedDocument.body.firstChild.nodeType === 1 && parsedDocument.querySelector("parsererror") === null
    } catch (_error) {
        return false
    }
}

const Component: NamedExoticComponent<TProps> = memo(({ content }: TProps): ReactElement => {
    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const displayContent: string = useMemo(
        (): string =>
            isString(content) ? content : isObject(content) ? getValueFromTranslatedObject(content) : String(),
        [getValueFromTranslatedObject, content]
    )

    return (
        <Suspense fallback={<Spinner className="my-3" />}>
            {displayContent ? (
                <div className="rich-text-renderer">
                    {!isStringValidHTML(displayContent) ? (
                        <CKEditor
                            editor={Editor}
                            disabled
                            disableWatchdog
                            config={{ menuBar: {}, toolbar: {} }}
                            data={displayContent}
                            onChange={emptyCallback}
                            onError={emptyCallback}
                            onBlur={emptyCallback}
                            onFocus={emptyCallback}
                            onReady={emptyCallback}
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: sanitizeContent(displayContent) }} />
                    )}
                </div>
            ) : null}
        </Suspense>
    )
}, propsChecker)

Component.displayName = "RickTextRenderer"

export { Component as RichTextRenderer, TProps as TRichTextRendererProps }
