import { FC, type LazyExoticComponent, lazy } from "react"

import { useTranslation } from "react-i18next"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import type { TRichTextRendererProps } from "@/shared/rich-text-renderer"

import { ChapterComponent, IContent } from "Manager/chapter/models/types"

import NextButton from "./NextButton"

interface IProps {
    component: IContent
    onNext: (component: ChapterComponent) => void
    isLast: boolean
    disabledBtn: boolean
}

const RichTextRenderer: LazyExoticComponent<FC<TRichTextRendererProps>> = lazy(
    (): Promise<{ default: FC<TRichTextRendererProps> }> => import("@/shared/rich-text-renderer")
)

const Content: FC<IProps> = ({ component, onNext, isLast = false, disabledBtn }) => {
    const { t } = useTranslation()
    const onClick = () => {
        onNext(component)
    }

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    return (
        <div className="container--phase">
            <div className="mt-3 mt-lg-0 px-3 px-lg-0">
                <h2 className="h5 font-extrabold text-uppercase color-brand text-center text-lg-left">{t("LEARN")}</h2>
                <h3 className="h2 font-extrabold font-italic text-center text-lg-left">
                    {getValueFromTranslatedObject(component?.title)}
                </h3>

                <RichTextRenderer content={component?.content} />

                <div className="text-center my-4.5">
                    <NextButton isLast={isLast} onClick={onClick} disabled={disabledBtn} />
                </div>
            </div>
        </div>
    )
}

export default Content
